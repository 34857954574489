<template>
  <div>
    <l-button
      type="primary"
      class="g-top_right_btn"
      @click="toDetail()"
    >
      新增商品
    </l-button>
    <l-search @search="search(1)" @reset="reset">
      <l-select v-model="classifyId" placeholder="请选择分类">
        <l-select-option v-for="(item, index) in classifyList" :key="index" :value="item.id">
          {{ item.name }}
        </l-select-option>
      </l-select>
      <l-input v-model="keyword" placeholder="请输入关键词" />
    </l-search>
    <!-- 列表区域-STR -->
    <l-table-common
      :data-source="tableData"
      @change="search"
    >
      <l-table-column
        data-index="id"
        title="流水号"
      />
      <l-table-column
        data-index="name"
        title="商品名称"
      />
      <l-table-column
        data-index="price"
        title="参考价格"
      />
      <l-table-column
        data-index="classify_id"
        title="分类"
      >
        <template slot-scope="scope">{{ scope }}</template>
      </l-table-column>
      <l-table-column data-index="banner" title="封面图">
        <template slot-scope="scope">
          <img :src="scope" class="p-product-banner" />
        </template>
      </l-table-column>

      <l-table-column title="操作">
        <template slot-scope="scope">
          <l-button type="link" @click="toDetail(scope.id)">
            编辑
          </l-button>
          <l-button type="link" @click="deleteProduct(scope.id)">
            删除
          </l-button>
        </template>
      </l-table-column>
    </l-table-common>
    <!-- 列表区域-END -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      classifyId: undefined,
      keyword: '',
      tableData: {
        total: 0,
        page: 1,
        size: 8,
        list: []
      },
    }
  },
  computed: {
    ...mapState({
      classifyList: state => state.auth.classifyList,
      productList: state => state.auth.productList
    })
  },
  mounted() {
    this.getClassify()
    this.search(1)
  },
  methods: {
    async getClassify() {
      await this.$store.dispatch('auth/classifyList', {
        page: 1,
        size: 65535
      })
    },
    reset() {
      this.classifyId = undefined
      this.keyword = ''
      this.search(1)
    },
    async search(page) {
      if (page && page.current) {
        page = page.current
      } else {
        page = page || this.tableData.page
      }
      try {
        const data = await this.$store.dispatch('auth/productList', {
          classifyId: this.classifyId,
          keyword: this.keyword,
          page: page,
          size: this.tableData.size
        })
        this.tableData = data || []
      } catch (e) {
        this.tableData = this.$options.data().tableData
      }
    },
    toDetail(id) {
      this.$toPage('/productDetail', {
        id: id || ''
      })
    },
    async deleteProduct(id) {
      this.$confirm({
        title: '是否删除，删除后将无法恢复？',
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          await this.$store.dispatch('auth/deleteProduct', {
            id
          })
          this.search()
        }
      })
    }
  }
}
</script>

<style lang="scss">
.p-product-banner {
  display: inline-block;
  width: 240px;
  height: auto;
  min-height: 135px;
  border: 1px solid #e6e6e6;
}
</style>
